import React, { Component } from 'react'

import Cleave from 'cleave.js/react';
// eslint-disable-next-line
import CleavePhone from 'cleave.js/dist/addons/cleave-phone.us'
export class phoneauth extends Component {

  state = {
    requestedPhone: false,
    recaptchaVerified:false,
    phone:'',
    phoneFormatted:'',
    showBaseForm:false
  }
  initialize = ()=>{
    window.recaptchaVerifier = new window.firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': function (response) {
        console.log(response);
        this.setState({
          recaptchaVerified:true
        })
      }
    });
  }
  //this clears the recaptcha so that people who input the incorrect phone number can try again with a different number
  clear = ()=>{
    return //to test
    //   window.recaptchaVerifier.clear();
    //  requestAnimationFrame(()=>{
    //    this.initialize()
    //  })
  }
  makePhoneFalse = ()=>{
    this.setState({
      requestedPhone:false
    })
  }
  componentDidMount = () => {
    try {
    // window.firebase.auth().settings.appVerificationDisabledForTesting = true; // Debug only, remove for prod 
    this.initialize();
    window.makePhoneFalse = this.makePhoneFalse;
    } catch (e) {
      console.log('I might be on a server so window object does not exist', e)
    }
  }
  signInWithPhoneNumber = () => {
    const phoneNumber = this.state.phone;
    const appVerifier = window.recaptchaVerifier;
    window.firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        this.setState({
          requestedPhone: true
        })
        this.clear();
      }).catch((error)=>{
        this.clear()
        console.log('phone number is probably invalid')
        this.makePhoneFalse();
        setTimeout(x=>{
          this.initialize();
        },500)
      });
  }
  authCodeHandler = e => {
    e.preventDefault(); window.confirmationResult.confirm(this.state.code).then(result => {
      const user = result.user;
      console.log(user, 'user');
      this.props.setData(this.state);
    }).catch(e => {
      console.log(e)
    })
  }
authCodeRequestHandler = e => {e.preventDefault();this.signInWithPhoneNumber();}
  render() {
    console.log(this.state)
    return !this.state.showBaseForm?(<div className="phoneAuthWrapper" style={{ textAlign: 'center' }}>
        {!this.state.showBaseForm && !this.state.requestedPhone && (<form className="verifyRequest" onSubmit={this.authCodeRequestHandler}>
          <label htmlFor="phone"> Please verify your Phone Number</label>
          <Cleave className="verifyInput" type="tel" id="phone" name="phone"
              placeholder="xxx xxx xxxx" options={{phone: true, phoneRegionCode: 'US'}} 
              onChange={(e)=>{this.setState({phone: '+1'+e.target.rawValue, phoneFormatted: '+1 '+e.target.value})}} />
          <SignInButton></SignInButton>
        </form>)}
        {this.state.requestedPhone ? (
          <React.Fragment>
          <button className='buttonModal' onClick={()=>{this.makePhoneFalse();
            setTimeout(x=>{
              this.initialize();
            },500)
          }}>Retry</button>
          <form className="verifyCode" onSubmit={this.authCodeHandler} >
            <label htmlFor="code"> Please enter the code you received:</label>
            <input 
              className="verifyInput"
              onChange={e => this.setState({ code: e.target.value })}
              type="tel" placeholder="Code" name="code"></input>
            {/* <input type="submit" className='buttonModal'></input> */}
            {/* <button type='submit' id='sign-in-button' className='buttonModal'></button> */}
            <SignInButton></SignInButton>
          </form>

          </React.Fragment>
        ) : null}
      </div>):(
        <p>old logic, must refactor</p>
      )
    
  }
}
function SignInButton({signInWithPhoneNumber}){
  return (<button
    type='submit'
    id='sign-in-button'
    className='buttonModal'
    onClick={signInWithPhoneNumber}
  > Verify</button>)
}
export default phoneauth
