import React, { Component } from 'react';

class Card extends Component {
  render() {
    return (
      <div className={`card ${this.props.background === 'dark' ? 'dark' : 'light'}`}>
        {this.props.children}
      </div>
    );
  }
}

export default Card;
