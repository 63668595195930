
import React, { Component } from 'react';
import CrateAndBarrel from '../../images/crateandbarrel.jpg';
import Amazon from '../../images/amazon.png';


class Registry extends Component {
  render() {
    return (
      <React.Fragment>
        <h2 className="registry">
            Registry
        </h2>
        <div className="giftProviders">
          <p>We have registered at the following 2 locations, click below to view the items we selected.</p>
          <br />
          <ul>
            <li>
              <a href="https://www.crateandbarrel.com/gift-registry/christina-mazzotta-and-nathan-guenther/r5939603" target="_blank"  rel="noopener noreferrer">
                <img src={CrateAndBarrel} className="crateAndBarrel" height="25px" width="150px" alt="Crate & Barrel" />
              </a>
            </li>
            <li>
              <a href="https://www.amazon.com/wedding/share/nateandxtina" target="_blank" rel="noopener noreferrer">
                <img src={Amazon} className="amazon" height="25px" width="75px" alt="Amazon" />
              </a>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Registry;
