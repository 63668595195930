import React, { Component } from 'react';
import background from '../images/header-bg.jpg';

class Hero extends Component {
  render() {
    return (
      <div className="heroWrapper" id="heroWrapper">
        <div className="titleWrapper">
          <h1 className="title">
        Christina & Nathan
            {' '}
            <br />
        Are Getting Married
          </h1>
          <h2 className="titleDate">
        JULY 14, 2019
          </h2>
        </div>
        <img alt="nateandchristina" src={background} />
      </div>
    );
  }
}

export default Hero;
