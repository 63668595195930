
import React, { Component } from 'react';
import ButtonModal from '../buttonModal';

class TheWedding extends Component {
  render() {
    return (
      <React.Fragment>
        <h2 className="theWedding">
            The Wedding
        </h2>
        <div className="whenAndWhere">
          <div className="when">
            <br />
            <h4>When</h4>
            <p>
July 14th, 2019
              <br />
              {' '}
5:00 - 10:00 PM
              <br />
              <br />
Please RSVP by May 1st, 2019
            </p>
          </div>
          <div className="whenAndWhereLine" />
          <div className="where">
            <br />
            <h4>Where</h4>
            <p>
Saratoga Springs Events & Weddings
              <br />
              <a href="https://goo.gl/maps/FPTDHpUeeEG2" target="_blank" rel="noopener noreferrer">22801 Big Basin Way, Saratoga, CA 95070</a>
            </p>
          </div>
          <br />
        </div>
        <ButtonModal>
              RSVP
        </ButtonModal>
      </React.Fragment>
    );
  }
}

export default TheWedding;
