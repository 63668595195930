  import React, { Component } from 'react';

class Burger extends Component {

    handleClick = ()=>{
        this.props.toggleNav()
    }

  render() {
    return (
        <div className='menuWrapper' onClick={this.handleClick}>
        <div className='menu'>
            <span>
                menu
            </span>
        </div>
            <div className='hamburgerWrapper'>
                <div className='hamburgerMenu'></div>
                <div className='hamburgerMenu'></div>
                <div className='hamburgerMenu'></div>
            </div>
        </div>
    );
  }
}

export default Burger;
