import React, { Component } from 'react';
import background from '../images/bottom.jpg';

class Bottom extends Component {
  render() {
    return (
      <div className="bottomWrapper" id="bottomWrapper">
        <img className="bottomImage" alt="nateandchristina" src={background} />
      </div>
    );
  }
}

export default Bottom;
