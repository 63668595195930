const firebase = window.firebase;

export const actionCodeGenerator = (inviteid, email) => ({
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be whitelisted in the Firebase Console.
  url: `http://localhost:3000?inviteid=${inviteid}&email=${email}`,
  // This must be true.
  handleCodeInApp: true,
});

export const cleanUrl = () => {
  const hash = new URL(window.location).hash;
  window.history.pushState({}, 'fresh', `/${hash}`);
};

const getInviteId = () => {
  const url = new URL(window.location.href);
  const inviteid = url.searchParams.get('inviteid');
  return inviteid;
};

const readInviteData = (func, inviteid) => {
  firebase.database().ref(`invites/${inviteid}/`).once('value').then((snapshot) => {
    func(snapshot);
  });
};

const doesUserExist = snapshot => (snapshot.val() && snapshot.val().exists) || false;

const saveEmailToLocalStateAndCleanUrl = function (email) {
  window.localStorage.setItem('emailForSignIn', email);
  cleanUrl();
};

const handleErrorAndCleanUrl = (error) => {
  console.log('soime error happened');
  cleanUrl();
};
const setEmailAndSendInviteLink = (inviteid, email) => {
  firebase.database().ref(`invites/${inviteid}/email`).set(email);
  firebase.auth().sendSignInLinkToEmail(email, actionCodeGenerator(inviteid, email))
    .then(() => {
      saveEmailToLocalStateAndCleanUrl(email);
    })
    .catch(handleErrorAndCleanUrl);
};


const tellUserIdIsNotRecognized = () => {
  //  alert('Your invite id is not recognized');
};

export const firstTimeVisitingHandler = () => {
  const inviteid = getInviteId();
  readInviteData((snapshot) => {
    const exists = doesUserExist(snapshot);
    if (exists) {
      // previous logic, remove in refactor for phone auth
      // const email = askUserForEmail();
      setEmailAndSendInviteLink(inviteid);
    } else {
      tellUserIdIsNotRecognized();
    }
  }, inviteid);
};

export const phoneAuthHandler = (updateComponentState) => {
  const inviteid = getInviteId();
  if (inviteid) {
    console.log(inviteid);
    updateComponentState(inviteid);
    // renderInviteUsed(inviteid);
    cleanUrl();
  }
};

const isThisSignInWithEmailLink = () => (
  firebase.auth().isSignInWithEmailLink(window.location.href)
);
export const firebaseInitiate = function () {
  const executeWhenLoggedInStateChanges = (user) => {
    if (user) {
      //   userLoggedInhandler(updateComponentState)
    } else if (isThisSignInWithEmailLink()) {
      // emailLinkHandler()
    } else {
      firstTimeVisitingHandler();
    }
  };
  firebase.auth().onAuthStateChanged(executeWhenLoggedInStateChanges);
};

export const initialState = () => (`
{  
"name":"",
"inviteId":"",
"userId":"",
"email":""
}`);


export const getLocalState = () => localStorage.getItem('state');

export const updateLocalState = (state) => {
  // modal_open is false because it is ui state, which needs to be separated
  window.localStorage.setItem('state', JSON.stringify({ ...state, modal_open: false }));
};

export const initializeLocalState = function (state) {
  this.setState({
    ...state,
  });
};
