import React, { useState, useEffect, useContext } from 'react';
// import {useState} from "react"
import {
  WeddingConsumer,
  WeddingToggleConsumer,
  GlobalState,
} from '../../App';
import PhoneAuth from './phoneauth';

function SignInButton({ signInWithPhoneNumber }) {
  return (
    <button
      type="submit"
      id="sign-in-button"
      className="buttonModal"
    >
      {' '}
Verify
    </button>
  );
}


const BaseForm = ({ guests, name }) => {
  const [form, setForm] = useState({ name, can_we_expect: true, guests: Array(guests).fill('') });
  const [submit, setSubmit] = useState(false);
  const context = useContext(GlobalState);
  console.log(form);
  useEffect(() => {
    if (submit) {
      const uid = window.firebase.auth().currentUser && window.firebase.auth().currentUser.uid;
      if (uid) {
        window.firebase.database().ref(`Users/${uid}`).set(
          {
            ...form,
            usedInviteId: context.inviteId,
            guests: form.guests.filter(x => x),
            totalPartyCount: form.guests.filter(x => x).length + 1,
          },
        );
        window.firebase.database().ref(`invites/${context.inviteId}/used`).set(true);
        context.submitForm();
      }
    }
  }, [submit]);
  return (
    <React.Fragment>
      <div className="phoneAuthWrapper">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSubmit(true);
          }}
          className="verifyInput"
          method="post"
        >
          <label htmlFor="name"> Full Name:</label>
          <input required value={form.name} className="verifyInput" type="text" id="name" name="name" />
          <label htmlFor="can_we_expect">Attending:</label>
          <select required onChange={(e) => { setForm({ ...form, [e.target.name]: e.target.value }); }} className="verifyInput" type="text" id="can_we_expect" name="can_we_expect">
            <option />
            <option>Joyfully accepts</option>
            <option>Regretfully declines</option>
          </select>
          {/* <div>Guests:</div> */}
          {Array(guests).fill(null).map((x, id) => (
            <React.Fragment key={id}>
              <label htmlFor={`guest${id}`}>
Guest
                {id + 1}
                {' '}
Name:
              </label>
              <input onChange={(e) => { setForm({ ...form, guests: form.guests.map((val, ind) => (ind === id ? e.target.value : val)) }); }} className="verifyInput" type="text" id={`guest${id}`} name={`Guest${id}`} />
            </React.Fragment>
          ))}
          <SignInButton />
        </form>
      </div>
    </React.Fragment>
  );
};

export default () => {
  const [form, setForm] = useState(false);
  const [data, setData] = useState(false);
  const context = useContext(GlobalState);

  useEffect(() => {
    if (!context.inviteId) return;
    if (data.phone) {
      window.firebase.database().ref(`invites/${context.inviteId}/phoneData`).set(data.phone);
      setForm(true);
    }
  }, [data]);

  useEffect(() => {
    window.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setForm(true);
      }
    });
  }, []);
  if (context.used) {
    return (
      <WeddingConsumer>
        {modalState => (
          <div className={`overlay ${modalState ? 'open' : ''}`} id="overlay">
            <div className="overlay-background" id="overlay-background" />
            <WeddingToggleConsumer>
              {
              toggle => (
                <div onClick={toggle} className="exitButton">x</div>
              )
            }
            </WeddingToggleConsumer>
            <div className="overlay-content formSubmitMessage" id="overlay-content">
              <h3>You have already rsvp\'d</h3>
              <p>Please contact the host to make any changes.</p>
            </div>
          </div>
        )}
      </WeddingConsumer>
    );
  }
  if (context.submitted) {
    return (
      <WeddingConsumer>
        {modalState => (
          <div className={`overlay ${modalState ? 'open' : ''}`} id="overlay">
            <div className="overlay-background" id="overlay-background" />
            <WeddingToggleConsumer>
              {
              toggle => (
                <div onClick={toggle} className="exitButton">x</div>
              )
            }
            </WeddingToggleConsumer>
            <div className="overlay-content formSubmitMessage" id="overlay-content">
              <h3>Thank You!</h3>
              <p><span>Watch </span>
                <a href="https://nateandxtina.wedding">nateandxtina.wedding</a>
                {' '}for updates, and contact the host if you have questions.
              </p>
            </div>
          </div>
        )}
      </WeddingConsumer>
    );
  }
  return !context.inviteId ? (

    <React.Fragment>
      <WeddingConsumer>
        {modalState => (
          <div className={`overlay ${modalState ? 'open' : ''}`} id="overlay">
            <div className="overlay-background" id="overlay-background" />
            <WeddingToggleConsumer>
              {
            toggle => (
              <div onClick={toggle} className="exitButton">x</div>
            )
          }
            </WeddingToggleConsumer>
            <div className="overlay-content formSubmitMessage" id="overlay-content">
              <h3>Do you have your invite handy?</h3>
              <p>Please check with the host for an invite link.</p>
            </div>
          </div>
        )}
      </WeddingConsumer>

    </React.Fragment>
  ) : (
    <WeddingConsumer>
      {modalState => (
        <div className={`overlay ${modalState ? 'open' : ''}`} id="overlay">
          <WeddingToggleConsumer>
            {
            toggle => (
              <div onClick={toggle} className="exitButton">x</div>
            )
          }
          </WeddingToggleConsumer>

          <div className="overlay-background" id="overlay-background" />
          <div className="overlay-content" id="overlay-content">
            <div className="fa fa-times fa-lg overlay-close" id="overlay-close" />
            <h2 className="main-heading titleDate">RSVP</h2>
            <br />
            {
            form ? (<BaseForm name={context.name} guests={context.extraGuestsCount} />) : (<PhoneAuth setForm={setForm} setData={setData} />)
          }
          </div>
        </div>
      )}
    </WeddingConsumer>
  );
};
