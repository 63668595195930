import React, { Component } from 'react';
import Form from './form/demoform';
import { WeddingConsumer } from '../App';

class Modal extends Component {
  render() {
    return (
      <WeddingConsumer>
        {modalState => (
          <div id="parentmodal" className={`modal ${modalState ? 'open' : ''}`}>
            <Form />
          </div>
        )}
      </WeddingConsumer>
    );
  }
}

export default Modal;
