import React from 'react';
import { WeddingToggleConsumer } from '../App.js';

export default props => (
  <WeddingToggleConsumer>
    {toggle => (
      <button onClick={toggle} className="buttonModal">
        {props.children}
      </button>
    )
        }
  </WeddingToggleConsumer>
);
