
import React, { Component } from 'react';
import OurStoryData from '../../data/ourStory';

class OurStory extends Component {
  render() {
    const {
      metDate, metTitle, metContent, proposalDate, proposalTitle, proposalContent,
    } = OurStoryData;
    return (
      <React.Fragment>
        <div className={`storyWrapper ${this.props.background === 'cream' ? 'cream' : ''}`}>
          <h2>Our Story</h2>
          <br />
          <div className="howWeMet">
            <h5>{metDate}</h5>
            <h4>{metTitle}</h4>
            <p className="ourStory">
              {metContent}
            </p>
            <br />
          </div>
          <div className="proposal">
            <h5>{proposalDate}</h5>
            <h4>{proposalTitle}</h4>
            <p className="ourStory">
              {proposalContent}
            </p>
            <br />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OurStory;
