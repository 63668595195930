import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer className="footer" id="footer">
        <div className="love">
          <p>
Made with <span role="img" aria-label="heart">❤️</span> by Nathan and Christina.
            <br />
            <a href="mailto:nateandxtina@gmail.com" target="_top" rel=" noopener noreferrer">Email us</a>
            <br />
            <br />
            © 2019 nateandxtina.wedding
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
