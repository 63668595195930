import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import Card from './card';
import OurStory from './sections/ourStory';
import TheWedding from './sections/theWedding';
import GettingThere from './sections/gettingThere';
import Registry from './sections/registry';


class Cards extends Component {
  render() {
    return (
      <div className="cardsWrapper" id="cardsWrapper">
        <ScrollableAnchor id="our-story">
          <Card background="dark">
            <OurStory />
          </Card>
        </ScrollableAnchor>
        <ScrollableAnchor id="the-wedding">
          <Card background="light">
            <TheWedding />
          </Card>
        </ScrollableAnchor>
        <ScrollableAnchor id="getting-there">
          <Card background="dark">
            <GettingThere />
          </Card>
        </ScrollableAnchor>
        <ScrollableAnchor id="registry">
          <Card background="light">
            <Registry />
          </Card>
        </ScrollableAnchor>
      </div>
    );
  }
}

export default Cards;
