import React, { Component } from 'react';
import Burger from "./mobileNav"
import navLinks from "../data/nav"
class Nav extends Component {

  state= {
    open:false
  }

  toggleNav = ()=>{
    //changes the open state in order to determine whether the open-nav class will get applied to the headerWrapper
    this.setState({
      open:!this.state.open
    })
  }


  render() {
    return (
    <div className={"headerWrapper-Wrapper"+(this.state.open?' open-nav':'')} id='headerWrapper-Wrapper'>
          <header className='headerWrapper'>
              <p className='fontAndColor'>
                  #GoingGuenther
              </p>
              <nav className="navbar-nav-parent">
              <ul className="navbar-nav text-uppercase ml-auto">
              {
                navLinks.map(link=>{
                  return (
                    <li  key={link.name} className="nav-item">
                      <a className="nav-link js-scroll-trigger" href={link.path}>{link.name}</a>
                    </li>
                  )
                })
              }
            </ul>
              </nav>
              <Burger toggleNav={this.toggleNav} />
          </header>
          <div className={'mobile-nav-links'}>
          {
                navLinks.map(link=>{
                  return (
                    <li key={link.name} className="nav-item">
                      <a className="nav-link js-scroll-trigger" href={link.path}>{link.name}</a>
                    </li>
                  )
                })
              }
          </div>
        </div>
    );
  }
}

export default Nav;
