import React, { Component } from 'react';
import './App.css';
import Nav from "./components/nav"
import Hero from "./components/hero"
import Cards from "./components/cards"
import Modal from "./components/modal"
import Bottom from "./components/bottom"
import Footer from "./components/footer"
import {
  initialState,
  getLocalState,
  initializeLocalState,
  updateLocalState,phoneAuthHandler} from "./utility"

export const WeddingContext = React.createContext({});
export const WeddingProvider = WeddingContext.Provider;
export const WeddingConsumer = WeddingContext.Consumer;
export const WeddingToggleContext = React.createContext({});
export const WeddingToggleProvider = WeddingToggleContext.Provider;
export const WeddingToggleConsumer = WeddingToggleContext.Consumer;
export const GlobalState = React.createContext({});

export class App extends Component {

  state={
    modal_open:false,
    setState:this.setState,
    submitForm:()=>{
      this.setState({submitted:true})
    }
  }
  componentDidUpdate = ()=>{
    updateLocalState(this.state)
  }
  componentDidMount = ()=>{
    const state = JSON.parse( getLocalState() || initialState())
    initializeLocalState.call(this,state);
    phoneAuthHandler((inviteId)=>{
      window.firebase.database().ref(`invites/${inviteId}`).once('value').then((snapshot)=>{
        const values = snapshot.val();
        if(values){
          this.setState({
            inviteId,
            ...values
          });
        }
        return
      })
    })
  }
  toggleModal= ()=>{
    this.setState({
      modal_open:!this.state.modal_open
    })

    document.getElementById('headerWrapper-Wrapper').classList.toggle('blur');
    document.getElementById('heroWrapper').classList.toggle('blur');
    document.getElementById('cardsWrapper').classList.toggle('blur');
    document.getElementById('bottomWrapper').classList.toggle('blur');
    document.getElementById('footer').classList.toggle('blur');
  }

  render() {
    console.log(this.state)
    return (
<React.Fragment>
<GlobalState.Provider value={this.state}>
      <WeddingToggleContext.Provider value={this.toggleModal}>
        <WeddingContext.Provider value={this.state.modal_open}>
                <Modal />
        </WeddingContext.Provider>
          <Nav />
          <Hero />
          <Cards />
          <Bottom />
          <Footer />
      </WeddingToggleContext.Provider>  
</GlobalState.Provider>
</React.Fragment>
    );
  }
}

