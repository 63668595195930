import React, { Component } from 'react';

class GettingThere extends Component {
  render() {
    return (
      <React.Fragment>
        <h2 className="gettingThere">
            Getting There
        </h2>
        <div className="gettingThere">
          <br />
          <div className="transportation">
            <h4>Transportation</h4>
            <p>Flying into the San Jose Airport (SJC) is much easier and closer to all locations. San Francisco (SFO) or Oakland (OAK) are alternatives; Uber is readily available.</p>
            <ul>
              <li>Flights to San Jose (SJC) Airport: ~15 min drive:</li>
              <ul>
                <li><a href="https://bit.ly/2CaBZiG" target="_blank" rel="noopener noreferrer">Google Flights Search Template</a></li>
                <li><a href="https://swa.is/2Ca99PB" target="_blank" rel="noopener noreferrer">Southwest Orlando (MCO) Nonstop Direct</a></li>
              </ul>
              <br />
              <li>Flight to San Francisco (SFO) Airport: ~1 hour drive:</li>
              <ul>
                <li><a href="https://bit.ly/2C4RmJE" target="_blank" rel="noopener noreferrer">Google Flights Search Template</a></li>
                <li><a href="https://bit.ly/2TWD9pM" target="_blank" rel="noopener noreferrer">United Orlando (MCO) Nonstop Direct</a></li>
              </ul>
            </ul>
          </div>
          <div className="whenAndWhereLine" />
          <br />
          <br />
          <div className="accomodation">
            <h4>Accommodation</h4>
            <p>
We have discounted block rates from 7.13.19 - 7.15.19 at the following 2 hotels.
            When booking, just reference
              <i>"Guenther & Mazzotta Wedding".</i>
            </p>
            <ul>
              <li><a href="https://goo.gl/maps/2LF7EodwP9o" target="_blank" rel="noopener noreferrer">DoubleTree by Hilton Hotel Campbell - Pruneyard Plaza:</a></li>
              <ul>
                <li>1995 S Bascom Ave, Campbell, CA 95008</li>
                <li>~20 minute drive/Uber from venue</li>
                <li><a href="tel:1-408-559-4300">(408) 559-4300</a></li>
              </ul>
              <br />
              <li><a href="https://goo.gl/maps/qXPPzPgRQuD2" target="_blank" rel="noopener noreferrer">Los Gatos Garden Inn:</a></li>
              <ul>
                <li>46 E Main St, Los Gatos, CA 95030</li>
                <li>~16 minute drive/Uber from venue</li>
                <li><a href="tel:1-408-354-6446">(408) 354-6446</a></li>
              </ul>
            </ul>
          </div>
          <div className="whenAndWhereLine" />
          <br />
          <br />
          <div className="things-to-do">
            <h4>Things to do</h4>
            <p>
There is plenty to keep you busy in the
              <i> San Francisco Bay Area / Silicon Valley. </i>
            Check out our
              {' '}
              <a href="https://bit.ly/2B9zEFb" target="_blank" rel="noopener noreferrer">list of recommendations</a>
              {' '}
and feel free to ask us any questions.
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default GettingThere;
